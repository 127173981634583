import React from "react";
import { Link } from "react-router-dom";
import "./card.scss";
import ReactTimeAgo from "react-time-ago";
import ReactDOM from "react-dom";
import IMG from "../assets/img/NEWS.png";
import PDF from "../assets/img/news-pdf.png";
function Cards({ card, url }) {
  console.log("card", card);
  var appData = {
    extensions: ["doc", "docx", "pdf", "ppt", "rtf", "swf", "txt", "xls"],
  };
  function isSpecialExtension(url, extensionsArray) {
    var extension = getExtensionFromUrl(url);
    if (extension === "") {
      return false;
    } else {
      if (extensionsArray.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    }
  }
  function getExtensionFromUrl(url) {
    var extension = "";
    var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    if (url.match(pattern) && url.match(pattern)[1]) {
      extension = url.match(pattern)[1];
    }
    return extension;
  }
  return (
    <>
      {isSpecialExtension(card.doc, appData.extensions) ? (
        <li>
          <Link to={card.doc} className="card" target="_blank">
            <img src={PDF} className="card__image" alt={card.title} />
            <div className="card__overlay">
              <div className="card__header">
                <div className="card__header-text">
                  <h3 className="card__title">{card.title}</h3>
                  <span className="card__status">
                    {typeof card.lastmodified === "undefined" ? (
                      ""
                    ) : (
                      <ReactTimeAgo date={card.lastmodified} />
                    )}
                  </span>
                </div>
              </div>
              <p className="card__description">
                {card.description ? card.description.substring(0, 100) : ""}
              </p>
            </div>
          </Link>
        </li>
      ) : (
        <>
          {card.doc === "" ? (
            <Link to={`${url}${card.slug}`} className="InretestBoxaa">
              <h1>{card.title}</h1>
            </Link>
          ) : (
            <li>
              <Link to={`${url}${card.slug}`} className="card">
                <img
                  src={card.doc ? card.doc : IMG}
                  className="card__image"
                  alt={card.title}
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">{card.title}</h3>
                      <span className="card__status">
                        {typeof card.lastmodified === "undefined" ? (
                          ""
                        ) : (
                          <ReactTimeAgo date={card.lastmodified} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  );
}

export default Cards;
