import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import "./networks.scss";
import Maps from "./maps";
import Breadcrumbs from "../../common/breadcumb";
function BranchAndATM() {
  let { slug } = useParams(null);
  const title = slug.replace("-", " ");
  if (slug == "atm-location") {
    slug = "atm";
  }
  if (slug == "extension-counter") {
    slug = "extension";
  }
  const [networks, setNetworks] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}${slug}`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [selectedBranch, setSelectedBranch] = React.useState({
    id: null,
    lat: null,
    lng: null,
  });
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        if (slug === "extension") {
          setNetworks(response.data?.extension.en);
        } else {
          setNetworks(response.data?.branch?.en);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";
  const branchChange = (event) => {
    setSelectedBranch({
      id: event.target.getAttribute("data-id"),
      lat: Number(event.target.getAttribute("data-lat")),
      lng: Number(event.target.getAttribute("data-lng")),
    });
  };

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="branchesMainBox">
          <div className="wrapper">
            <div className="branch-container">
              <div className="BranchesItemList">
                <div className="accordion" id="accordionExample">
                  {networks?.map((network, index) => {
                    console.log("network", network);
                    return (
                      <div key={index} className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="headingOne"
                          onClick={branchChange}
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne-${network.id}`}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            data-id={network.id}
                            data-lat={network.latitude}
                            data-lng={network.longitude}
                          >
                            {network.title}
                          </button>
                        </h2>
                        <div
                          key={index}
                          id={`collapseOne-${network.id}`}
                          className={`accordion-collapse collapse ${
                            index == 0 ? "show" : ""
                          } `}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="branchesData">
                              <p>
                                <strong> Address : </strong> {network.address}
                              </p>
                              <p>
                                <strong> Telephone Number : </strong>
                                {network.phone}
                              </p>
                              <p>
                                <strong>
                                  {slug == "extension"
                                    ? "Incharge :"
                                    : network.id == 1
                                    ? ""
                                    : network.id == 17 ||
                                      network.id == 20 ||
                                      network.id == 13 ||
                                      network.id == 4
                                    ? "Regional Manager : "
                                    : ""}
                                </strong>
                                {network.RMname}
                              </p>
                              <p>
                                <strong>
                                  {slug == "extension"
                                    ? "Incharge :"
                                    : network.id == 1
                                    ? ""
                                    : network.id == 17 ||
                                      network.id == 20 ||
                                      network.id == 13 ||
                                      network.id == 4
                                    ? network.manager_tag === "Branch Manager"
                                      ? "Branch Manager : "
                                      : "Assistant Branch Manager :"
                                    : network.manager_tag === "Branch Manager"
                                    ? "Branch Manager : "
                                    : "Assistant Branch Manager :"}
                                </strong>
                                {network.manager}
                              </p>
                              <p>
                                <strong> Email : </strong>
                                {network.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="BranchesMaps">
                <Maps data={networks} selected={selectedBranch} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchAndATM;
