import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import Cards from "../../common/card";
import "./interestRate.scss";
import Pagination from "react-js-pagination";
function InterestRate() {
  const { slug } = useParams(null);
  const [interest, setInterst] = React.useState();
  const [isPage, setActive] = React.useState(1);
  const APIURL = `${process.env.REACT_APP_URL}interest_rates/all/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${isPage}`);
        setInterst(response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [isPage]);
  if (loading) return "";
  if (error) return "";
  const handlePageChange = (pageNumber) => {
    setActive(pageNumber);
  };
  console.log("interest", interest);
  return (
    <>
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="detail-parent">
              <div className="description">
                {interest.items.en.length === 0
                  ? "No Content Found"
                  : interest.items.en.map((interest) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: interest?.description,
                          }}
                        ></div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="InsidePageContainer">
    //   <div className="faq-box">
    //     <div className="wrapper">
    //       <ul className="cards interestss">
    //         {interest.items.en.length === 0
    //           ? "No Content Found"
    //           : interest.items.en.map((interest) => {
    //               return (
    //                 <>
    //                   <Cards
    //                     key={interest.id}
    //                     card={interest}
    //                     url="../rates/interest-rates/"
    //                   />
    //                 </>
    //               );
    //             })}
    //       </ul>
    //       {interest.items.en.length < 10 ? (
    //         ""
    //       ) : (
    //         <div className="parent-pagination">
    //           <Pagination
    //             activePage={isPage}
    //             itemsCountPerPage={interest.per_page}
    //             totalItemsCount={interest.total}
    //             pageRangeDisplayed={6}
    //             firstPageText="First Page"
    //             lastPageText="Last Page"
    //             itemClass="page-item"
    //             linkClass="page-link"
    //             onChange={handlePageChange}
    //           />
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
}

export default InterestRate;
