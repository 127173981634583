import axios from "axios";
import React from "react";
import { useParams } from "react-router";
import "./faqs.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "../../common/accordion";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";

function Faqs() {
  const { slug } = useParams(); // Correct usage of useParams (no need for null)
  TabTitle(`GMBF | ${slug}`);

  const [faqs, setFaqs] = React.useState([]);
  const APIURL = `${process.env.REACT_APP_URL}/faq`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        console.log("response", response);
        setFaqs(response.data.items.en); // Assuming the data structure has items.en

        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="faq-container">
              {faqs.length === 0 ? (
                "No Content Found"
              ) : (
                <Tabs>
                  <TabList>
                    {faqs.map((cat, index) => {
                      return (
                        <Tab key={index}>
                          {cat.title} {/* Tab Title */}
                        </Tab>
                      );
                    })}
                  </TabList>
                  {faqs.map((cat, index) => {
                    return (
                      <TabPanel key={index}>
                        <div
                          className="accordion"
                          id={`accordionExample-${index}`}
                        >
                          {cat.child?.length === 0
                            ? "No Content Found"
                            : cat.child.map((faq, faqIndex) => {
                                return (
                                  <div
                                    key={faqIndex}
                                    className="accordion-item"
                                  >
                                    <h2
                                      className="accordion-header"
                                      id={`heading-${faqIndex}`}
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-${faqIndex}`}
                                        aria-expanded="true"
                                        aria-controls={`collapse-${faqIndex}`}
                                      >
                                        {faq?.title}
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapse-${faqIndex}`}
                                      className="accordion-collapse collapse"
                                      aria-labelledby={`heading-${faqIndex}`}
                                      data-bs-parent={`#accordionExample-${index}`}
                                    >
                                      <div className="accordion-body">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: faq?.description,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
