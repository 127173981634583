import React from "react";
import "./team.scss";
import axios from "axios";
import { useParams } from "react-router";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import Errors from "../../common/error";
function BranchManager() {
  let { slug } = useParams(null);
  const [content, setContent] = React.useState();
  if (slug === "branch-managers") {
    slug = "branch-manager";
  }
  const APIURL = `${process.env.REACT_APP_URL}team/all/${slug}`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setContent(response.data.items.en);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="wrapper">
          <div className="BranchManagerContainer">
            {content.length === 0
              ? "No Team Found."
              : content.map((team) => {
                  return (
                    <div key={team.id} className="BranchManager">
                      <h2>{team?.name}</h2>
                      <p>
                        <b>Branch : </b>
                        {team?.branch}
                      </p>
                      <p>{team?.address}</p>
                      <p>{team?.contact}</p>
                      <p>{team?.email}</p>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchManager;
