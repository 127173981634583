import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import "./index.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { LangContext } from "../../context/LangContext";
const TopBar = ({ closeMobileNav }) => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const onClickNP = () => {
    lang.dispatch({ type: "NEPALI" });
  };
  const onClickEN = () => {
    lang.dispatch({ type: "ENGLISH" });
  };
  const [menu, setMenu] = React.useState();
  const MianData = useState([]);
  const APIURL = `${process.env.REACT_APP_URL}menu/top`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    const LangBtnLink = document.querySelectorAll(".LangBtn");
    LangBtnLink.forEach((LangBtnEl) => {
      LangBtnEl.addEventListener("click", () => {
        document.querySelector(".active")?.classList.remove("active");
        LangBtnEl.classList.add("active");
      });
    });
  });

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setMenu(response.data.items);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (nep) {
    MianData.current = menu?.np;
  } else {
    MianData.current = menu?.en;
  }
  if (loading) return "...";
  if (error) return "";

  return (
    <>
      <ul className="TopMenuItems">
        {MianData.current.map((menu) => {
          return (
            <>
              {typeof menu.child === "undefined" ? (
                <li key={menu.id}>
                  {menu.link === "" ? (
                    <Link to={menu.slug} onClick={closeMobileNav}>
                      {menu.title}
                    </Link>
                  ) : (
                    <Link
                      to={menu.link}
                      onClick={closeMobileNav}
                      target="_blank"
                    >
                      {menu.title}
                    </Link>
                  )}
                </li>
              ) : (
                <li key={menu.id} className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={"i"}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {menu.title}
                  </Link>
                  <ul className="dropdown-menu">
                    {menu.child.map((child) => {
                      switch (menu.slug) {
                        case "grievance":
                          return (
                            <>
                              <li key={child.id}>
                                {child.link === "" ? (
                                  <Link
                                    onClick={closeMobileNav}
                                    className="dropdown-item"
                                    to={`grievance/${child.slug}`}
                                  >
                                    {child.title}
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={closeMobileNav}
                                    className="dropdown-item"
                                    to={child.link}
                                    target="_blank"
                                  >
                                    {child.title}
                                  </Link>
                                )}
                              </li>
                            </>
                          );
                          break;
                        default:
                          return (
                            <>
                              <li key={child.id}>
                                {child.link === "" ? (
                                  <Link
                                    onClick={closeMobileNav}
                                    className="dropdown-item"
                                    to={child.slug}
                                  >
                                    {child.title}
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={closeMobileNav}
                                    className="dropdown-item"
                                    to={child.link}
                                    target="_blank"
                                  >
                                    {child.title}
                                  </Link>
                                )}
                              </li>
                            </>
                          );
                      }
                    })}
                  </ul>
                </li>
              )}
            </>
          );
        })}
      </ul>
      <div className="ActionBox">
        <div className="language">
          <ul>
            <li
              className="LangBtn active"
              onClick={() => {
                onClickEN();
              }}
            >
              En
            </li>
            <li
              className="LangBtn nepali"
              onClick={() => {
                onClickNP();
              }}
            >
              ने
            </li>
          </ul>
        </div>
        <div className="LoginBox">
          <button onClick={closeMobileNav}>
            <Link to={`https://ebanking.gmbf.com.np/ebank/#/`} target="_blank">
              <AccountCircleIcon fontSize="small" /> e-Login
            </Link>
          </button>
        </div>
        <div className="SearchBox">
          <Link to={"search"} onClick={closeMobileNav}>
            <SearchIcon fontSize="small" />
          </Link>
        </div>
      </div>
    </>
  );
};
export default TopBar;
